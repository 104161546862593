<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Log Rules</h2>
      <p class="mb-3">
        There are four important rules when dealing with logarithms that can help simplify or
        rearrange equations:
      </p>

      <v-simple-table class="mb-2 pl-6" style="max-width: 400px">
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Product:}$" />
            </td>
            <td>
              <stemble-latex content="$\text{log(x y) = log(x) + log (y)}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{Quotient:}$" />
            </td>
            <td>
              <stemble-latex content="$\text{log(x / y) = log(x) - log (y)}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{Exponent:}$" />
            </td>
            <td>
              <stemble-latex content="$\text{log(x}^{\text{y}}\text{) = y log(x)}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{Reciprocal:}$" />
            </td>
            <td>
              <stemble-latex content="$\text{log(1/x) = log(x}^{-1}\text{) = -log(x)}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3">
        These rules are incredibly important when solving equations. While sometimes it will only
        simplify an equation, sometimes it is essential in order to rearrange or solve the equation.
        These rules apply to ALL logarithmic functions, regardless of the base. Using these rules,
        answer each of the questions shown below:
      </p>

      <p class="mb-0 pb-0">
        <stemble-latex content="$\text{a) Which of the expressions below IS NOT equivalent to}$" />
        <stemble-latex content="$\log\left(\displaystyle{\frac{4a}{c}}\right)\text{?}$" />
      </p>

      <v-radio-group v-model="inputs.input1" class="pl-8 mt-0 pt-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          dense
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0 pb-0">
        <stemble-latex content="$\text{b) Which of the expressions below IS equivalent to}$" />
        <stemble-latex content="$\log(3x^2) - \log(4x)\text{?}$" />
      </p>

      <v-radio-group v-model="inputs.input2" class="pl-8 mt-0 pt-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="option.value"
          dense
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3">
        Determine the <b>value</b> of each of the following expressions. Be sure to use at least 3
        significant figures in your answers.
      </p>

      <p class="mb-2">
        <stemble-latex
          :content="`$\\text{c) }\\log(${coefc1}x) + \\log\\left(\\displaystyle{\\frac{${coefc2}}{x}}\\right)$`"
        />
      </p>

      <calculation-input
        v-model="inputs.log1"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex
          :content="`$\\text{d) }\\ln(${coefd1}x^2) - \\ln(${coefd2}x) - \\ln(${coefd3}x)$`"
        />
      </p>

      <calculation-input
        v-model="inputs.log2"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question372',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        log1: null,
        log2: null,
      },
      options1: [
        {text: '$\\log(4a)-\\log(c)$', value: 'a'},
        {text: '$-\\log(4ac)$', value: 'b'},
        {text: '$\\log(4a) + \\log(c^{-1})$', value: 'c'},
        {text: '$-\\log\\left(\\displaystyle{\\frac{c}{4a}}\\right)$', value: 'd'},
      ],
      options2: [
        {text: '$\\log(12x^3)$', value: 'a'},
        {text: '$\\log(x(3x-4))$', value: 'b'},
        {text: '$-\\log\\left(\\displaystyle{\\frac{4}{3x}}\\right)$', value: 'c'},
        {text: '$-\\log(12x^3)$', value: 'd'},
      ],
    };
  },
  computed: {
    c1() {
      return this.taskState.getValueBySymbol('c1').content;
    },
    coefc1() {
      return this.c1.toInt();
    },
    c2() {
      return this.taskState.getValueBySymbol('c2').content;
    },
    coefc2() {
      return this.c2.toInt();
    },
    d1() {
      return this.taskState.getValueBySymbol('d1').content;
    },
    coefd1() {
      return this.d1.toInt();
    },
    d2() {
      return this.taskState.getValueBySymbol('d2').content;
    },
    coefd2() {
      return this.d2.toInt();
    },
    d3() {
      return this.taskState.getValueBySymbol('d3').content;
    },
    coefd3() {
      return this.d3.toFloat().toFixed(1);
    },
  },
};
</script>
